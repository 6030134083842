import * as routeHelpers from "../../lib/routeHelpers";
import * as routes from "../../constants/routes";
import Button from "../Button";
import React, { useEffect, useState } from "react";
import styles from "./ScreenEditClub.module.css";
import TextInput from "../TextInput";
import {
  editClub,
  getClub,
  newClub,
  ClubWithDetails,
} from "../../lib/api/clubs";
import { useHistory, useRouteMatch } from "react-router-dom";

interface MatchParams {
  clubId: string;
}

type ScreenEditClubProps = {
  new?: boolean;
};

function ScreenEditClub(props: ScreenEditClubProps) {
  const [existingClub, setExistingClub] = useState<ClubWithDetails | undefined>(
    undefined
  );
  const [name, setName] = useState("");

  const match = useRouteMatch<MatchParams>(routes.EDIT_CLUB);
  const clubId = match ? match.params.clubId : null;

  const history = useHistory();

  useEffect(() => {
    if (!clubId) {
      return;
    }
    getClub(clubId)
      .then((club) => {
        setExistingClub(club);
        setName(club.name);
      })
      .catch(() => {
        // TODO: err
      });
  }, [clubId]);

  const submitForm = () => {
    if (props.new) {
      newClub({ name })
        .then((club) => {
          // TODO: Success
          // Redirect to edit page?
          history.push(routeHelpers.editClub(club.id));
        })
        .catch(() => {
          // TODO: err
        });
    }
    if (!!existingClub) {
      editClub({ ...existingClub, name })
        .then(() => {
          // Success
        })
        .catch(() => {
          // TODO: err
        });
    }
  };

  return (
    <div className={styles.container}>
      <div>{props.new ? "New Club" : "Edit Club"}</div>
      <TextInput value={name} onChangeText={setName} placeholder="Name" />
      <Button onClick={submitForm}>Submit</Button>
    </div>
  );
}

export default ScreenEditClub;
