import * as routeHelpers from "../../lib/routeHelpers";
import * as routes from "../../constants/routes";
import Button from "../Button";
import React, { useEffect, useState } from "react";
import styles from "./ScreenEditAccount.module.css";
import TextInput from "../TextInput";
import {
  editAccount,
  getAccount,
  newAccount,
  Account,
} from "../../lib/api/accounts";
import { useHistory, useRouteMatch } from "react-router-dom";

interface MatchParams {
  accountId: string;
}

type ScreenEditAccountProps = {
  new?: boolean;
};

function ScreenEditAccount(props: ScreenEditAccountProps) {
  const [existingAccount, setExistingAccount] = useState<Account | undefined>(
    undefined
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [admin, setAdmin] = useState(false);

  const match = useRouteMatch<MatchParams>(routes.EDIT_ACCOUNT);
  const accountId = match ? match.params.accountId : null;

  const history = useHistory();

  useEffect(() => {
    if (!accountId) {
      return;
    }
    getAccount(accountId)
      .then((account) => {
        setExistingAccount(account);
        setFirstName(account.firstName || "");
        setLastName(account.lastName || "");
        setEmail(account.email);
        setAdmin(account.admin);
      })
      .catch(() => {
        // TODO: err
      });
  }, [accountId]);

  const submitForm = () => {
    if (props.new) {
      newAccount({ firstName, lastName, email, password, admin })
        .then((account: Account) => {
          // TODO: Success
          // Redirect to edit page?
          history.push(routeHelpers.editAccount(account.id));
        })
        .catch(() => {
          // TODO: err
        });
    }
    if (!!existingAccount) {
      editAccount({ ...existingAccount, firstName, lastName, email, admin })
        .then(() => {
          // Success
        })
        .catch(() => {
          // TODO: err
        });
    }
  };

  return (
    <div className={styles.container}>
      <div>{props.new ? "New Account" : "Edit Account"}</div>
      <TextInput
        value={firstName}
        onChangeText={setFirstName}
        placeholder="First Name"
      />
      <TextInput
        value={lastName}
        onChangeText={setLastName}
        placeholder="Last Name"
      />
      <TextInput value={email} onChangeText={setEmail} placeholder="Email" />
      {props.new ? (
        <TextInput
          value={password}
          onChangeText={setPassword}
          placeholder="Initial Password"
          type="password"
          autoComplete="off"
        />
      ) : null}
      <input
        type="checkbox"
        checked={admin}
        onChange={(event) => setAdmin(event.target.checked)}
      />
      <label>Is Admin?</label>
      <Button onClick={submitForm}>Submit</Button>
    </div>
  );
}

export default ScreenEditAccount;
