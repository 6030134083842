import * as routes from "../../constants/routes";
import PrivateRoute from "../PrivateRoute";
import React from "react";
import ScreenAccounts from "../ScreenAccounts";
import ScreenClubAccounts from "../ScreenClubAccounts";
import ScreenClubs from "../ScreenClubs";
import ScreenEditAccount from "../ScreenEditAccount";
import ScreenEditClub from "../ScreenEditClub";
import ScreenLogin from "../ScreenLogin";
import ScreenTest from "../ScreenTest";
// import styles from "./App.module.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path={routes.NEW_CLUB}>
          <ScreenEditClub new={true} />
        </PrivateRoute>
        <PrivateRoute path={routes.EDIT_CLUB}>
          <ScreenEditClub new={false} />
        </PrivateRoute>
        <PrivateRoute path={routes.CLUB_ACCOUNTS}>
          <ScreenClubAccounts />
        </PrivateRoute>
        <PrivateRoute path={routes.CLUBS}>
          <ScreenClubs />
        </PrivateRoute>
        <PrivateRoute path={routes.NEW_ACCOUNT}>
          <ScreenEditAccount new={true} />
        </PrivateRoute>
        <PrivateRoute path={routes.EDIT_ACCOUNT}>
          <ScreenEditAccount new={false} />
        </PrivateRoute>
        <PrivateRoute path={routes.ACCOUNTS}>
          <ScreenAccounts />
        </PrivateRoute>
        <Route path={routes.LOGIN}>
          <ScreenLogin />
        </Route>
        <PrivateRoute>
          <ScreenTest />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
