import axios from "axios";
import { getAuthToken } from "../withFirebaseAuth";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 3000,
});

instance.interceptors.request.use(
  function (config) {
    return getAuthToken()
      .then((authToken) => {
        if (authToken) {
          config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
      })
      .catch((error) => {
        console.log(error);
        return config;
      });
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
