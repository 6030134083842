export const editAccount = (accountId: string): string => {
  return `/accounts/${accountId}/edit`;
};

export const editClub = (clubId: string): string => {
  return `/clubs/${clubId}/edit`;
};

export const editClubAccounts = (clubId: string): string => {
  return `/clubs/${clubId}/accounts`;
};
