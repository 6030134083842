import "firebase/auth";
import firebase from "firebase/app";
import React from "react";
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth";

/** Documentation */
/** These props are provided by withFirebaseAuth HOC */
// signInWithEmailAndPassword,
// createUserWithEmailAndPassword,
// signInWithGoogle,
// signInWithFacebook,
// signInWithGithub,
// signInWithTwitter,
// signInAnonymously,
// signOut,
// setError,
// user,
// error,
// loading,

// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};
const firebaseApp = firebase.initializeApp(config);
const firebaseAppAuth = firebaseApp.auth();

// TODO: Add providers here if needed in the future.
export const withFirebaseAuthHOC = <T extends object>(
  Component: React.ComponentType<T & WrappedComponentProps>
): React.ComponentType<T> => {
  return withFirebaseAuth<T>({
    providers: undefined,
    firebaseAppAuth,
  })(Component);
};

export const getCurrentUser = (): Promise<firebase.User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebaseAppAuth.onAuthStateChanged(
      (user: firebase.User | null) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });
};

export const getAuthToken = (): Promise<string | null> => {
  return getCurrentUser().then((currentUser) => {
    if (!currentUser) {
      return null;
    }
    return currentUser.getIdToken();
  });
};

export type FirebaseAuthProps = WrappedComponentProps;

export default withFirebaseAuthHOC;
