import * as routes from "../../constants/routes";
import React from "react";
import styles from "./NavMenu.module.css";
import { Link } from "react-router-dom";
import withFirebaseAuth, {
  FirebaseAuthProps,
} from "../../lib/withFirebaseAuth";

function NavMenu(props: FirebaseAuthProps) {
  return (
    <div className={styles.container}>
      <div className={styles.menuTitle}>TT Director Admin</div>
      <div className={styles.horizontalDivider} />
      <Link to={routes.ACCOUNTS} className={styles.link}>
        <div className={styles.menuNavItem}>Accounts</div>
      </Link>
      <Link to={routes.CLUBS} className={styles.link}>
        <div className={styles.menuNavItem}>Clubs</div>
      </Link>
      <div className={styles.menuNavItem} onClick={props.signOut}>
        Logout
      </div>
    </div>
  );
}

export default withFirebaseAuth(NavMenu);
