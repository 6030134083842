import classNames from "classnames";
import React from "react";
import styles from "./TextInput.module.css";

type TextInputProps = {
  onChangeText?: (value: string) => void;
} & React.HTMLProps<HTMLInputElement>;

const WrappedComponent = React.forwardRef(function TextInput(
  props: TextInputProps,
  ref: any
) {
  const { name, onChange, onChangeText, value, ...restOfProps } = props;
  return (
    <input
      type="text"
      className={classNames(styles.textInput, props.className)}
      value={value}
      onChange={(event) => {
        onChange && onChange(event);
        onChangeText && onChangeText(event.target.value);
      }}
      {...restOfProps}
      ref={ref}
    />
  );
});

export default WrappedComponent;
