import classNames from "classnames";
import React from "react";
import styles from "./Button.module.css";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const WrappedComponent = React.forwardRef(function Button(
  props: ButtonProps,
  ref: any
) {
  return (
    <button
      className={classNames(styles.button, props.className)}
      {...props}
      ref={ref}
    />
  );
});

export default WrappedComponent;
