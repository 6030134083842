import * as routeHelpers from "../../lib/routeHelpers";
import * as routes from "../../constants/routes";
import Button from "../Button";
import NavMenu from "../NavMenu";
import React, { useEffect, useState } from "react";
import styles from "./ScreenAccounts.module.css";
import { deleteAccount, getAccounts, Account } from "../../lib/api/accounts";
import { Link } from "react-router-dom";

function ScreenAccounts() {
  const [accounts, setAccounts] = useState<Account[]>([]);

  const refreshAccounts = () =>
    getAccounts().then((accounts) => {
      setAccounts(Object.values(accounts));
    });

  useEffect(() => {
    getAccounts().then((accounts) => {
      setAccounts(Object.values(accounts));
    });
  }, []);

  const onDeleteAccount = (account: Account) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${account.email}? This will remove the user and remove them from all associated clubs.\nThis action cannot be undone.`
      )
    ) {
      return;
    }
    deleteAccount(account.id).then(() => refreshAccounts());
  };

  return (
    <div className={styles.container}>
      <div className={styles.navBar}>DASHBOARD</div>
      <div className={styles.mainContentContainer}>
        <div className={styles.menuContainer}>
          <NavMenu />
        </div>
        <div className={styles.rightContainer}>
          <Link to={routes.NEW_ACCOUNT}>New Account</Link>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Admin?</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => (
                <tr key={account.id}>
                  <td>{account.email}</td>
                  <td>{account.admin ? "Admin" : "No"}</td>
                  <td>
                    <Link to={routeHelpers.editAccount(account.id)}>Edit</Link>
                    <Button onClick={() => onDeleteAccount(account)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ScreenAccounts;
