import instance from "./instance";

type NewAccount = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  admin: boolean;
};

export type Account = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  admin: boolean;
};

const responseData = (response: any) => response.data;

export function getAccounts() {
  return instance
    .request({
      method: "GET",
      url: `/accounts`,
    })
    .then(responseData)
    .then((data: Account[]) => {
      const result: { [id: string]: Account } = {};
      data.forEach((account: Account) => {
        result[account.id] = account;
      });
      return result;
    });
}

export function getAccount(accountId: string): Promise<Account> {
  return instance
    .request({
      method: "GET",
      url: `/accounts/${accountId}`,
    })
    .then(responseData);
}

export function newAccount(account: NewAccount): Promise<Account> {
  return instance
    .request({
      method: "POST",
      url: `/accounts`,
      data: account,
    })
    .then(responseData);
}

export function editAccount(account: Account) {
  return instance
    .request({
      method: "POST",
      url: `/accounts/${account.id}`,
      data: account,
    })
    .then(responseData);
}

export function deleteAccount(accountId: string) {
  return instance
    .request({
      method: "DELETE",
      url: `/accounts/${accountId}`,
    })
    .then(responseData);
}
