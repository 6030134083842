import Button from "../Button";
import React, { useEffect, useState } from "react";
import styles from "./ScreenLogin.module.css";
import TextInput from "../TextInput";
import withFirebaseAuth, {
  FirebaseAuthProps,
} from "../../lib/withFirebaseAuth";
import { Redirect } from "react-router-dom";

function ScreenLogin(props: FirebaseAuthProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO: Loading state
    props.signInWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    if (!props.error) {
      return;
    }
    alert(props.error);
    // if (props.error.message) {
    //   if (props.error.code === "auth/invalid-email") {
    //     alert("Invalid email format.");
    //     return;
    //   }
    //   if (
    //     props.error.code === "auth/user-not-found" ||
    //     props.error.code === "auth/wrong-password"
    //   ) {
    //     alert("Email or password is invalid.");
    //     return;
    //   }
    //   alert(props.error.message);
    //   return;
    // }
  }, [props.error]);

  if (!!props.user) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.navBar}>Login</div>
      <form onSubmit={loginEmail}>
        <TextInput value={email} onChangeText={setEmail} placeholder="Email" />
        <TextInput
          value={password}
          onChangeText={setPassword}
          placeholder="Password"
          type="password"
        />
        <Button type="submit">Login</Button>
      </form>
    </div>
  );
}

export default withFirebaseAuth(ScreenLogin);
