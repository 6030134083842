import * as routeHelpers from "../../lib/routeHelpers";
import * as routes from "../../constants/routes";
import Button from "../Button";
import NavMenu from "../NavMenu";
import React, { useEffect, useState } from "react";
import styles from "./ScreenClubs.module.css";
import { deleteClub, getClubs, Club } from "../../lib/api/clubs";
import { Link } from "react-router-dom";

function ScreenClubs() {
  const [clubs, setClubs] = useState<Club[]>([]);

  const refreshClubs = () =>
    getClubs().then((clubs) => {
      setClubs(Object.values(clubs));
    });

  useEffect(() => {
    getClubs().then((clubs) => {
      setClubs(Object.values(clubs));
    });
  }, []);

  const onDeleteClub = (club: Club) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${club.name}? This action cannot be undone.`
      )
    ) {
      return;
    }
    deleteClub(club.id).then(() => refreshClubs());
  };

  return (
    <div className={styles.container}>
      <div className={styles.navBar}>DASHBOARD</div>
      <div className={styles.mainContentContainer}>
        <div className={styles.menuContainer}>
          <NavMenu />
        </div>
        <div className={styles.rightContainer}>
          <Link to={routes.NEW_CLUB}>New Club</Link>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th># Accounts</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {clubs.map((club) => (
                <tr key={club.id}>
                  <td>{club.name}</td>
                  <td>{club.accounts.length}</td>
                  <td>
                    <Link to={routeHelpers.editClubAccounts(club.id)}>
                      Accounts
                    </Link>
                    <Link to={routeHelpers.editClub(club.id)}>Edit</Link>
                    <Button onClick={() => onDeleteClub(club)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ScreenClubs;
