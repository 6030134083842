import * as routes from "../../constants/routes";
import Button from "../Button";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./ScreenClubAccounts.module.css";
import { useRouteMatch } from "react-router-dom";
import { getAccounts, Account } from "../../lib/api/accounts";
import {
  getClub,
  addAccountToClub,
  removeAccountFromClub,
  ClubWithDetails,
} from "../../lib/api/clubs";

interface MatchParams {
  clubId: string;
}

type ScreenClubAccountsProps = {};

function ScreenClubAccounts(props: ScreenClubAccountsProps) {
  const [club, setClub] = useState<ClubWithDetails | undefined>(undefined);

  const match = useRouteMatch<MatchParams>(routes.CLUB_ACCOUNTS);
  const clubId = match ? match.params.clubId : null;

  const refreshClub = () => {
    if (!clubId) {
      return null;
    }
    return getClub(clubId)
      .then((club) => setClub(club))
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (!clubId) {
      return;
    }
    getClub(clubId)
      .then((club) => setClub(club))
      .catch(() => {
        // TODO: err
      });
  }, [clubId]);

  const [accounts, setAccounts] = useState<Account[]>([]);
  useEffect(() => {
    getAccounts().then((accounts) => {
      setAccounts(Object.values(accounts));
    });
  }, []);

  if (!club) {
    return null;
  }

  const selectedAccountIds = new Set(club.accounts.map((a) => a.id));
  const options = accounts
    .filter((a) => !selectedAccountIds.has(a.id))
    .map((a) => ({ value: a.id, label: a.email }));

  return (
    <div className={styles.container}>
      <div>{`${club.name} - Accounts`}</div>
      <Select
        value={null}
        className={styles.select}
        options={options}
        isClearable={false}
        onChange={(option, { action }) => {
          if (action !== "select-option" || !option) {
            return;
          }
          const selectedAccountId = (option as { value: string }).value;
          addAccountToClub(club.id, selectedAccountId).then(refreshClub);
        }}
      />
      {club.accounts.map((account) => {
        return (
          <div key={account.id}>
            <div>{`${account.email}`}</div>
            <Button
              onClick={() => {
                removeAccountFromClub(club.id, account.id).then(refreshClub);
              }}
            >
              Remove
            </Button>
          </div>
        );
      })}
    </div>
  );
}

export default ScreenClubAccounts;
