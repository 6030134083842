import instance from "./instance";
import { Account } from "./accounts";

type NewClub = {
  name: string;
};

export type Club = {
  id: string;
  name: string;
  accounts: string[];
};

type EditClub = {
  id: string;
  name: string;
};

export type ClubWithDetails = {
  id: string;
  name: string;
  accounts: Account[];
};

const responseData = (response: any) => response.data;

export function getClubs() {
  return instance
    .request({
      method: "GET",
      url: `/clubs`,
    })
    .then(responseData)
    .then((data: Club[]) => {
      const result: { [id: string]: Club } = {};
      data.forEach((club: Club) => {
        result[club.id] = club;
      });
      return result;
    });
}

export function getClub(clubId: string): Promise<ClubWithDetails> {
  return instance
    .request({
      method: "GET",
      url: `/clubs/${clubId}`,
    })
    .then(responseData);
}

export function newClub(club: NewClub): Promise<Club> {
  return instance
    .request({
      method: "POST",
      url: `/clubs`,
      data: club,
    })
    .then(responseData);
}

export function editClub(club: EditClub) {
  return instance
    .request({
      method: "POST",
      url: `/clubs/${club.id}`,
      data: club,
    })
    .then(responseData);
}

export function deleteClub(clubId: string) {
  return instance
    .request({
      method: "DELETE",
      url: `/clubs/${clubId}`,
    })
    .then(responseData);
}

export function addAccountToClub(clubId: string, accountId: string) {
  return instance
    .request({
      method: "POST",
      url: `/clubs/${clubId}/accounts`,
      data: { accountId },
    })
    .then(responseData);
}

export function removeAccountFromClub(clubId: string, accountId: string) {
  return instance
    .request({
      method: "DELETE",
      url: `/clubs/${clubId}/accounts/${accountId}`,
    })
    .then(responseData);
}
